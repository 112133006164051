<template>
  <span>{{formatted}}</span>
</template>

<script>
import {Duration} from "luxon";

export default {
  name: 'Duration',
  props: ['seconds'],
  computed: {
    formatted: function(){
      return Duration.fromObject({years: 0, quarters: 0, months: 0, weeks: 0, days: 0, hours: 0, minutes: 0, seconds: this.seconds, milliseconds: 0}).toFormat('hh:mm:ss');
    }
  }
}
</script>

<style scoped>

</style>